<template>
    <div   >
        <div style=" background:white;line-height:40px;">
            <div style="margin-top:3px;display:flex; ">
                <div style="margin-right:20px;font-size:14px;margin-left:5px;color:#F56C6C;font-weight:600">提示：只引用审核的订单</div>
                <el-button type="text" size="mini" icon="iconfont icon-chenggong1" @click="selSure" style="color:#409eff; height:35px;margin-top:3px;margin-right:20px;">选择</el-button> 
                <span style=" margin-left:20px;font-size:12px;margin-right:10px;">字段查询</span>
                <el-select v-model="selparam" size="small" placeholder="请选择列名" clearable  style="width:150px; " >
                    <span v-for="item in poCol" :key='item.key'>
                        <el-option :label="item.title" :value="item.key"></el-option>
                    </span>
                </el-select>
                <el-input :placeholder="input_hold" v-model="textParam" @keyup.enter.native="stfgChange" size="small" style="width:380px;margin-left:10px;height:30px;margin-top:3px;">
                    <el-select v-model="oper" slot="prepend"   style="width:120px; " >
                        <span v-for="item in operArr" :key='item.IDSEQ'>
                            <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                        </span>
                    </el-select>
                    <el-button  slot="append" icon="el-icon-search" @click="stfgChange" ></el-button>
                </el-input>
 
            </div>
        </div>  
         <el-table  :data="poData" 
              border                            
              size="small"
              v-loading="loading"
              element-loading-text="加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 251, 255, 0.8)"
              :height="subHeight" 
              :row-key="getRowKey"
              ref="refreq"
              highlight-current-row
              :row-class-name="tableRowClass"
              style="overflow:auto;margin-top:1px;"> 
            <span v-for="(item ) in poCol" :key="item.key" > 
                <el-table-column  v-if="item.thide==='N' && item.key!=='STFG'"
                    :prop="item.key"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    sortable
                    :min-width="item.width">
                </el-table-column> 
                <el-table-column  v-if="item.key==='STFG'"
                    :prop="item.key"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    :min-width="item.width">
                    <template width="60" slot-scope="scope" >
                        <i v-if="scope.row.STFG==='Y'" class="iconfont icon-xuanze" style="color:green"></i>
                        <i v-if="scope.row.STFG==='N'" class="iconfont icon-xuanze"  ></i>
                        <i v-if="scope.row.STFG==='X'" class="iconfont icon-xuanze" style="color:red"></i>
                    </template>
                </el-table-column>  
            </span> 
        </el-table>
        <!-- 分页查询 -->
        <div style="display:flex; line-height:35px;align-items:center;border:1px solid #ccc;padding-left:10px;" >              
            <Page :total="dataCount" :page-size="rowSize" show-total :current="current" @on-change="changePage"></Page>
            <span style="color:#606266;margin:7px 10px;font-size:12px;">每页</span>
            <el-select v-model="rowSize" size="small" style="width:70px;" @change="initPage">
                    <el-option 
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
            </el-select>
            <span style="color:#606266;margin:7px 10px;font-size:12px;">跳至</span>
            <el-input v-enter-number size="small" v-model="goPage" style="width:50px;"></el-input> 
            <span style="color:#606266;margin:7px 5px;font-size:12px;">页</span>
            <el-button size="small" @click="goNext" type="primary" style="width:50px;margin-left:15px;height:30px;">GO</el-button>
        </div>
        <el-table  :data="dtlData" 
              border                            
              size="small"
              v-loading="loading"
              element-loading-text="加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 251, 255, 0.8)"
              :height="subHeight" 
              :row-key="getRowKey2"
              ref="refdtl"
              highlight-current-row
              style="overflow:auto;margin-top:1px;"> 
              <el-table-column 
                prop="SEL"
                label="选择"
                fixed="left"
                align="center"  >
                <template width="60" slot-scope="scope" >
                    <el-checkbox  true-label="Y"  false-label="N" v-model="scope.row.SEL" ></el-checkbox>
                </template>
            </el-table-column> 
            <span v-for="(item ) in reqDtlCol" :key="item.key" > 
                <el-table-column  v-if="item.thide==='N'"
                    :prop="item.key"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    sortable
                    :min-width="item.width">
                </el-table-column> 
            </span> 
        </el-table>
 
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
            </div>
            <div style=" margin:15px 10px">
                {{prompt}}
            </div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {getBaseData,getTabColOrData} from '../../api/user'; 
import {paramData} from '../../api/Select' 
export default {
    data () {
        return {
            payModal:false,
            promptWin:false,
            stfgSet:'ALL',
            prompt:'',
            input_hold:'请输入内容...',
            selparam:'',
            textParam:'',
            matRow:{},
 
            goPage:'',
            curind:-1,
            poData:[],
            dtlData:[],
            loading:false,
            current:1,
            paymth:'ali',
 
            allList:[], //询价单集合
 
            v_username :this.$cookies.get('v_username' ), //用户名称 
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            v_url:this.$store.state.queryUrl , //api请求路径   
            condition:{
                idno:'', //询价单 号
                matno:'', //商品编码
                telno:'', //电话号码
                stfg:'wait', //订单状态
                dttm:'', //下单日期
                todttm:'', //结束下单日期
                facno:'', //厂牌
            },
            options: [{
                value: 5,
                label: '5'
                }, {
                value: 10,
                label: '10'
                }, {
                value: 20,
                label: '20'
                }, {
                value: 50,
                label: '50'
            }],
            dataCount:0,
            rowSize:10,
            reqDtlCol:[],
            poCol:[],
            operArr:[],
            oper:'like',
            colory:'#959997', //审核颜色
            colorx:'#e00e66', //作废颜色
        }
    },
    components: {
      //alipay,  
    },
    computed: {
 
        subHeight: function() {
            return (window.innerHeight - 530) + 'px';
        }, 
    },
    mounted () {
        //
        paramData('PARENTNO','41113','V_SYS_BASE').then((res)=>{
            this.operArr =res.data.result
        });   
    },
    created () {
        if (this.v_username){
            this.getTabCol() //表列集合
            this.getPoList('') //询价单主数据
        }else{
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG },
            });
            window.open(routeUrl.href, '_self'); 
        }
    },
    methods: {
        selSure(){
            //console.log(JSON.stringify(this.dtlData))
            let data_=[]
            for(let k=0;k<this.dtlData.length;k++){
                if(this.dtlData[k].SEL==='Y'){
                    data_.push(this.dtlData[k])
                }
            }
            this.$emit('child-sure',data_)
        },
        //对指定列进行合计
        getSummary(param){
            const {columns ,data}=param
            const sums=[]
            columns.forEach((column,index)=>{
                if (index===0){
                    sums[index]='每页小计'
                    return
                }
                const values =data.map(item=>Number(item[column.property]));
                if(column.property==='AMT'){
                    sums[index]=values.reduce((prev,curr)=>{ //为数组中每一个元素依次执行回调函数
                        const value=Number(curr)
                        if(!isNaN(value)){
                            return Number(Number(prev) +Number(curr) ).toFixed(2)
                        }else{
                            return prev
                        }
                    },0)
                    console.log('index:'+index)
                    //sums[index]
                }
            })
            return sums
        },
        //表行记录css
        tableRowClass({row, rowIndex}) {
            if (row.ORDSTFG  === 'Y') {
                return 'success-row';
            } else if (row.ORDSTFG ==='X') {
                return 'cancel-row';
            }
            return '';
        },
        getRowKey(row){
            return row.IDSEQ
        },
        getRowKey2(row){
            return row.IDSEQ+'-'+row.LSTSEQ
        },
        //状态变更
        stfgRec(row,val){
            let v_comm=''
            if (val==='Y'){
                v_comm='审核'
            }else if (val==='N'){
                v_comm='反审核'
            }else if (val==='X'){
                v_comm='作废'
            }
            this.$confirm('你确定要'+v_comm+'此订单吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                var vm=this;
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'user/updateRec?username='+vm.v_username+'&password='+vm.v_password,
                    data: {p_key:'idseq',p_value:row.IDSEQ,p_table:'ord',p_cond:' set ordstfg=\''+val+'\' '},
                }).then(res=>{      
                    if(res.data.code =='200')  {           
                        Object.assign(row,{ORDSTFGNM:v_comm })
                    }else{
                        this.promptWin =true
                        this.prompt=res.data.result
                    }        
                })
            }).catch(()=>{})
             
        },
        // 行点击事件
        rowClick(row){
             this.getDtlList(row.IDSEQ)
        },
        //状态查询
        stfgChange(){
            let cond_=''
            if (this.selparam){
                if (this.oper==='like'){
                    cond_=cond_+' and '+this.selparam+' like \'%'+this.textParam+'%\''
                }else{
                    cond_=cond_+' and '+this.selparam+this.oper+'\''+this.textParam+'\''
                }
            }
            if (this.stfgSet!=='ALL'){
               cond_=cond_+' and ordstfg=\''+this.stfgSet+'\''
            }
            this.getPoList(cond_)
        }, 
        goNext(){
            if (this.goPage){
                this.changePage(this.goPage)
                this.current =Number(this.goPage)
            }else{
                this.$alert('请输入跳至页数', '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type:'info'
                })
            }
        },
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.rowSize;
            var _end = index * this.rowSize;
            this.poData = this.allList.slice(_start,_end);
        },
        //初始化查询页条数
        initPage(){
            //产品明细
            this.dataCount =this.allList.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (this.allList.length < this.rowSize){
                this.poData = this.allList          
            }else{
                this.poData =this.allList.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
            }
            this.$nextTick(() => { //有固定列时 查询后重新布局
                this.$refs.refreq.doLayout();
            }) 
        },
 
        //po单 
        getPoList(cond){
            let vm =this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_po_ord','stfg','Y','','','','',cond ).then((res) => { 
                this.allList =res.data 
                this.initPage() 
                if (res.data.length>0){
                    this.getDtlList(res.data[0].IDSEQ)
                    this.$refs['refreq'].setCurrentRow(res.data[0])
                }else{
                    this.dtlData=[]
                }
            })
        },
        //询价单明细
        getDtlList(idseq){
            let vm =this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_po_orddtl','idseq',idseq,'','','','',' order by adddttm' ).then((res) => { 
                this.dtlData =res.data 
            })
        },
        //获取表列头字段  v-enter-number
        getTabCol(){
            var vm=this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','35','fty','HEAD','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.poCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        thide: res.data[k].THIDE,                       
                    })
                }
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','35','fty','HEAD2','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.reqDtlCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        thide: res.data[k].THIDE,  
                        fhide: res.data[k].FHIDE,                      
                        disabled: res.data[k].DISABLED, 
                    })
                }
            })
        },
    }
}
</script>
<style scoped lang="less">
    .item-width  {
        width: 48%;
        color:#409EFF;
    }
  
</style>
<style lang="less">
    .el-table {
        th {
            padding: 0 ;
        }
        thead{
            height: 15px;
            color:rgb(11, 11, 59);
            font-size:12px;
            background:rebeccapurple;
        }
        td {
            padding: 5px ;
            font-size:11px;
        } 
    }
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:5px !important;}
    //.el-dialog__headerbtn i { background:white;font-size: 20px;  }
    .el-table .success-row {
        color:#959997;
    }
    .el-table .cancel-row {
        color: #e00e66;
    }
</style>